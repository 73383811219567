<script setup>
const props = defineProps(["modelValue", "items", "selection"]);
const emit = defineEmits(["copy", "share", "close"]);
</script>
<template>
  <teleport to="body">
    <div class="selection-panel-el pb-10" :class="{ isVisible: modelValue }">
      <div class="d-flex align-center">
        <!-- <v-btn icon="xs" @click="emit('close')"> -->
        <!-- <mdicon name="close" class="icon mx-3" /> -->
        <!-- </v-btn> -->
        <div class="selection-details ml-2">{{ transl("Selected") }}: {{ items }}</div>
        <div @click="emit('close')" class="ml-auto icon-btn">
          <v-svg
            src="/icons/close-icon.svg"
            :options="{ width: '20px', height: '20px', color: 'black' }"
          ></v-svg>
        </div>
      </div>
      <div class="selection-button mt-4" @click="emit('copy')">
        <img src="/icons/copy-icon.svg" alt="copy icon" class="svg-icon" />
        <span class="ml-2">{{ transl("Copy") }}</span>
      </div>
      <div class="selection-button" @click="emit('share')">
        <img src="/icons/share-ios-black.svg" alt="share icon" class="svg-icon" />
        <span class="ml-2">{{ transl("Share") }}</span>
      </div>
    </div>
  </teleport>
</template>

<style scoped>
.selection-panel-el {
  position: fixed;
  bottom: 0px;
  left: 50%;
  border-radius: 20px;
  box-shadow: 0px 4px 11px 0px var(--menu-shadow);
  color: var(--root-white);
  background-color: var(--root-white);
  transform: translate3d(-50%, 100%, 0);

  transition: transform 0.2s cubic-bezier(0.2, 0, 0.2, 1);
  pointer-events: none;
  z-index: 100000;
  width: 100%;
  max-width: var(--screen-max-width);
  color: var(--root-black);
}

.selection-panel-el.isVisible {
  pointer-events: all;
  transition: transform 0.2s cubic-bezier(0.2, 0, 0.2, 1);
  transform: translate3d(-50%, 20px, 0);
}

.svg-icon {
  width: 25px;
}

.selection-details {
  width: 110px;
  font-size: 16px;
}
.selection-button {
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 18px;
  height: 50px;
  padding: 10px 20px;
  /* border-radius: 10px; */
}
.selection-button:hover {
  background-color: rgb(240, 239, 239);
}
.icon-btn {
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
}
.icon-btn:hover {
  background-color: rgba(238, 238, 238, 0.267);
}
</style>
